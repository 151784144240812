import { ICreateContactPersonPresenter } from "../controllers/CreateContactPersonController";
import { CreateContactPersonViewModel } from "../vms/CreateContactPersonViewModel";
import { FormResponse } from "@/forms/FormResponse";
import { PhoneDataForm } from "@/forms/person/PhoneDataForm";
import { PersonDataForm } from "@/forms/person/PersonDataForm";
import { SimpleStringStorage } from "@/storage/SimpleStringStorage";
import { CreateOperatorPhoneNumberViewModel } from "../vms/CreateOperatorPhoneNumberViewModel";
import { PhoneDataSubForm } from "@/forms/person/PhoneDataSubForm";
import {
  Plant,
  PlantContactPerson,
  PlantContactPersonPhoneNumber
} from "../services/PlantService";
import { CountriesStorageHandler } from "@/storage/storageHandlers/CountriesStorageHandler";
import { SalutationStorageHandler } from "@/storage/storageHandlers/SalutationStorageHandler";

export class CreateContactPersonPresenter
  implements ICreateContactPersonPresenter {
  public personDataForm: PersonDataForm;
  public phoneDataForm: PhoneDataForm;

  private areaCodes: any[] = [];
  private id?: string;

  public constructor(
    private vm: CreateContactPersonViewModel,
    plant: Plant,
    contactPerson: PlantContactPerson,
    private storage: SimpleStringStorage
  ) {
    this.personDataForm = new PersonDataForm(
      this.vm,
      this.setPersonDataFormValid
    );

    this.phoneDataForm = new PhoneDataForm(this.vm, this.setPhoneDataFormValid);

    this.personDataForm.init();
    this.phoneDataForm.init();

    this.initSelections();
    this.vm.plant = plant;

    if (!!contactPerson) {
      this.id = contactPerson.id;
      this.initContactPersonFromContactPerson(contactPerson);
    }
  }

  public setPersonDataFormValid(context: any, valid: boolean) {
    context.openPhoneDataStepButtonDisabled = !valid;
  }

  public setPhoneDataFormValid(context: any, valid: boolean) {
    context.updatePlantButtonDisabled = !valid;
  }

  public set visibleView(visible: boolean) {
    this.vm.visibleView = visible;
    if (!visible) {
      setTimeout(() => (this.vm.goBack = true), 300);
    }
  }

  public set personDataStepCompleted(completed: boolean) {
    this.vm.personDataStepCompleted = completed;
    this.vm.formStep = completed ? 2 : 1;
  }

  public set updatePlantResponse(response: FormResponse<string>) {
    this.vm.updatePlantRequest = response;

    if (!response.loading && !response.error) {
      if (!this.id) {
        this.vm.plant.contactPersons.push(this.contactPerson);
      }
      this.vm.updatedPlant = response.data;
    }
  }

  public addPhoneDataSubForm() {
    const phoneNumberVm = new CreateOperatorPhoneNumberViewModel();
    phoneNumberVm.areaCode.items = this.areaCodes;
    const subForm = new PhoneDataSubForm(
      phoneNumberVm,
      () => undefined,
      this.phoneDataForm
    );
    subForm.init();
    this.phoneDataForm.addSubForm(subForm);
    this.vm.phoneNumbers.push(phoneNumberVm);
    this.vm.updatePlantButtonDisabled = true;
  }

  public removePhoneDataSubForm(index: number) {
    this.phoneDataForm.removeSubFormByIndex(index);
    this.vm.phoneNumbers.splice(index, 1);
    if (
      this.vm.phoneNumbers.every(
        field => !!field.areaCode.selected && !!field.phoneNumber.value
      )
    ) {
      this.vm.updatePlantButtonDisabled = false;
    }
    if (this.vm.phoneNumbers.length === 0) {
      this.vm.updatePlantButtonDisabled = false;
    }
  }

  public standardChanged(checked: boolean, index: number) {
    this.vm.phoneNumbers.forEach((element, i) => {
      if (index === i) {
        element.isStandard.value = true;
      } else {
        element.isStandard.value = false;
      }
    });
  }

  public mounted(): void {
    this.vm.visibleView = true;
  }

  public set plant(plant: Plant) {
    this.vm.plant = plant;
  }

  public get plant(): Plant {
    return this.vm.plant;
  }

  public get contactPerson(): PlantContactPerson {
    return {
      id: this.id,
      salutation: this.vm.salutation.selected,
      firstName: this.vm.firstName.value,
      lastName: this.vm.lastName.value,
      company: this.vm.company.value,
      eMail: this.vm.mail.value,
      phoneNumbers: this.vm.phoneNumbers.map(field => ({
        areaCode: field.areaCode.selected,
        phoneNumber: field.phoneNumber.value,
        remarks: field.phoneAddition.value,
        isStandard: field.isStandard.value
      }))
    };
  }

  private initSelections() {
    this.vm.salutation.items = SalutationStorageHandler.getAllSalutationLongForms();

    this.areaCodes = CountriesStorageHandler.getAreaCodes();
  }

  private initContactPersonFromContactPerson(
    contactPerson: PlantContactPerson
  ) {
    this.personDataForm.setFieldValue("salutation", contactPerson.salutation);
    this.personDataForm.setFieldValue("firstName", contactPerson.firstName);
    this.personDataForm.setFieldValue("lastName", contactPerson.lastName);
    this.personDataForm.setFieldValue("company", contactPerson.company);
    this.personDataForm.setFieldValue("mail", contactPerson.eMail);
    for (const phoneNumber of contactPerson.phoneNumbers) {
      this.addPopulatedPhoneDataSubform(phoneNumber);
    }
  }

  private addPopulatedPhoneDataSubform(
    phoneNumber: PlantContactPersonPhoneNumber
  ) {
    const phoneNumberVm = new CreateOperatorPhoneNumberViewModel();
    phoneNumberVm.areaCode.items = this.areaCodes;
    const subForm = new PhoneDataSubForm(
      phoneNumberVm,
      () => undefined,
      this.phoneDataForm
    );
    subForm.init();
    this.phoneDataForm.addSubForm(subForm);
    subForm.setFieldValue("areaCode", phoneNumber.areaCode);
    subForm.setFieldValue("phoneNumber", phoneNumber.phoneNumber);
    subForm.setFieldValue("phoneAddition", phoneNumber.remarks);
    subForm.setFieldValue(
      "isStandard",
      phoneNumber.isStandard ? "true" : "false"
    );
    this.vm.phoneNumbers.push(phoneNumberVm);
  }
}
