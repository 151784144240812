import {
  Selection,
  Textfield,
  Request
} from "@/forms/ViewModelFormTypes";
import { CreateOperatorPhoneNumberViewModel } from "./CreateOperatorPhoneNumberViewModel";

export class CreateContactPersonViewModel {
  public plant: any = {};
  public visibleView: boolean = false;
  public goBack: boolean = false;
  public updatedPlant: string = "";

  public formStep: number = 1;
  public personDataStepCompleted: boolean = false;

  public openPhoneDataStepButtonDisabled: boolean = true;
  public updatePlantButtonDisabled: boolean = false;

  public updatePlantRequest: Request = { loading: false, error: "" };

  public salutation: Selection = { selected: "", error: "", items: [] };
  public firstName: Textfield = { value: "", error: "" };
  public lastName: Textfield = { value: "", error: "" };
  public company: Textfield = { value: "", error: "" };
  public mail: Textfield = { value: "", error: "" };

  public standardPhoneNumber: number = 0;
  public phoneNumbers: CreateOperatorPhoneNumberViewModel[] = [];
}
