










































































































































































































import Vue from "vue";
import Component from "vue-class-component";

import Card from "@/components/layout/Card.vue";
import Row from "@/components/layout/Row.vue";
import Column from "@/components/layout/Column.vue";
import Container from "@/components/layout/Container.vue";
import RequestButton from "@/components/basic/RequestButton.vue";

import { LocalStorage } from "../../storage/LocalStorage";

import { CreateContactPersonViewModel } from "@/plant/vms/CreateContactPersonViewModel";
import { CreateContactPersonPresenter } from "@/plant/presenters/CreateContactPersonPresenter";
import { CreateContactPersonController } from "@/plant/controllers/CreateContactPersonController";

import { PlantGraphQLService } from "@/plant/services/PlantGraphQLService";
import { AxiosGraphQLConnection } from "@/gateways/graphql/connections/AxiosGraphQLConnection";
import { AxiosLogger } from "@/logging/AxiosLogger";
import { ConsoleLogger } from "@/logging/ConsoleLogger";
import { Plant, PlantContactPerson } from "../services/PlantService";

@Component<CreateContactPersonView>({
  components: {
    Card,
    Container,
    Column,
    RequestButton,
    Row
  },
  props: {
    plant: {},
    contactPerson: {}
  },
  watch: {
    "vm.goBack"() {
      this.vm.visibleView = false;
      this.$emit("closed");
      this.$destroy();
    },
    "vm.updatedPlant"() {
      this.vm.visibleView = false;
      this.$emit("finished");
      this.$destroy();
    }
  }
})
export default class CreateContactPersonView extends Vue {
  private vm = new CreateContactPersonViewModel();
  private step = 1;
  private plant!: Plant;
  private contactPerson!: PlantContactPerson;

  private get controller(): CreateContactPersonController {
    const connection = new AxiosGraphQLConnection(
      new AxiosLogger(new ConsoleLogger())
    );
    return new CreateContactPersonController(
      new CreateContactPersonPresenter(
        this.vm,
        this.plant,
        this.contactPerson,
        new LocalStorage()
      ),
      new PlantGraphQLService(connection)
    );
  }

  private mounted() {
    this.controller.mounted();
  }
}
