import {
  IPlantService,
  Plant,
  PlantContactPerson
} from "../services/PlantService";
import { PersonDataForm } from "@/forms/person/PersonDataForm";
import { PhoneDataForm } from "@/forms/person/PhoneDataForm";
import { FormResponse } from "@/forms/FormResponse";
import { FormRequestHandler } from "@/forms/FormRequestHandler";

export class CreateContactPersonController {
  public constructor(
    private presenter: ICreateContactPersonPresenter,
    private plantService: IPlantService
  ) {}

  public mounted() {
    this.presenter.visibleView = true;
  }
  public closeButtonClicked(): void {
    this.presenter.visibleView = false;
  }

  public completePersonDataStepButtonClicked(): void {
    this.presenter.personDataStepCompleted = true;
  }

  public salutationSelected(salutation: string): void {
    this.presenter.personDataForm.setFieldValue("salutation", salutation);
  }

  public firstNameChanged(firstName: string): void {
    this.presenter.personDataForm.setFieldValue("firstName", firstName);
  }

  public lastNameChanged(lastName: string): void {
    this.presenter.personDataForm.setFieldValue("lastName", lastName);
  }

  public companyChanged(company: string): void {
    this.presenter.personDataForm.setFieldValue("company", company);
  }

  public mailChanged(mail: string): void {
    this.presenter.personDataForm.setFieldValue("mail", mail);
  }

  public closePhoneDataStepButtonClicked() {
    this.presenter.personDataStepCompleted = false;
  }

  public addPhoneDataRowButtonClicked() {
    this.presenter.addPhoneDataSubForm();
  }

  public removePhoneDataRowButtonClicked(index: number) {
    this.presenter.removePhoneDataSubForm(index);
  }

  public areaCodeSelected(areaCode: string, index: number) {
    this.presenter.phoneDataForm.subforms[index].setFieldValue(
      "areaCode",
      areaCode
    );
  }

  public phoneNumberChanged(phoneNumber: string, index: number) {
    this.presenter.phoneDataForm.subforms[index].setFieldValue(
      "phoneNumber",
      phoneNumber
    );
  }

  public phoneAdditionChanged(phoneAddition: string, index: number) {
    this.presenter.phoneDataForm.subforms[index].setFieldValue(
      "phoneAddition",
      phoneAddition
    );
  }

  public standardChanged(checked: boolean, index: number) {
    if (checked) {
      this.presenter.phoneDataForm.subforms.forEach((element, i) => {
        if (i === index) {
          element.setFieldValue("isStandard", "true");
        } else {
          element.setFieldValue("isStandard", "false");
        }
      });
    } else {
      this.presenter.phoneDataForm.subforms[index].setFieldValue(
        "isStandard",
        "false"
      );
    }
    this.presenter.standardChanged(checked, index);
  }

  public updatePlantButtonClicked() {
    const request = this.plantService.addPlantContactPerson(
      this.presenter.plant,
      this.presenter.contactPerson
    );
    FormRequestHandler.handle(
      request,
      response => (this.presenter.updatePlantResponse = response),
      "update-plant-failed"
    );
  }
}

export interface ICreateContactPersonPresenter {
  plant: Plant;
  visibleView: boolean;
  personDataStepCompleted: boolean;

  personDataForm: PersonDataForm;
  phoneDataForm: PhoneDataForm;

  updatePlantResponse: FormResponse<string>;
  contactPerson: PlantContactPerson;

  addPhoneDataSubForm(): void;
  removePhoneDataSubForm(index: number): void;
  standardChanged(checked: boolean, index: number): void;
}
